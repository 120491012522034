import styled from 'styled-components'
import Text from 'components/Text'

export const Wrapper = styled.div(({theme}) => ({
  padding: '60px 0',
  background: theme.palette.grey[900],

  [theme.breakpoints.down('laptopS')]: {
    paddingTop: 40,
    paddingBottom: 12,
  },
}))

export const BreadcrumbsWrapper = styled.div(({theme}) => ({
  marginBottom: 13,
}))

export const Title = styled(Text)(({theme}) => ({
  maxWidth: 670,
  fontSize: `39px`,
  lineHeight: 1.2,

  [theme.breakpoints.down('tablet')]: {
    wordBreak: 'break-all',
    fontSize: `22px`,
  },
}))

export const Bottom = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('laptopS')]: {
    marginBottom: 23,
  },
}))

export const BottomItem = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.common.white,

  '&:last-of-type': {
    marginLeft: 15,
  },
}))

export const BottomItemText = styled.div(({theme}) => ({
  marginLeft: 8,
}))

export const ShowMobile = styled.div(({theme}) => ({
  display: 'none',

  [theme.breakpoints.down('laptopS')]: {
    display: 'block',
  },
}))
