import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  paddingBottom: 60,

  [theme.breakpoints.down('tablet')]: {
    paddingBottom: 60,
    paddingLeft: 15,
    borderLeft: 'none',
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  paddingLeft: 40,
  paddingTop: 30,
  borderLeft: `1px solid ${theme.palette.grey[800]}`,
  maxWidth: 700,
  width: '100%',

  '& h1, h2, h3, h4, h5, h6': {
    marginBottom: 18,
    fontWeight: 700,
  },

  '& h1': {
    fontSize: theme.fontSizes.titleLg,
  },

  '& h2': {
    fontSize: theme.fontSizes.title,
  },

  '& h3': {
    fontSize: theme.fontSizes.titleXs,
  },

  '& h4': {
    fontSize: theme.fontSizes.md,
  },

  '& ol, ul': {
    padding: 0,
    marginLeft: 15,
  },

  '& ul': {
    padding: 0,
    marginLeft: 0,
    listStyle: 'none',

    '& li': {
      '::before': {
        content: "'•'",
        display: 'inline-block',
        marginRight: 10,
      },
    },
  },

  [theme.breakpoints.down('tablet')]: {
    paddingLeft: 15,
    borderLeft: 'none',
  },
}))
