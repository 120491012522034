import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import BlogBlockSection from '../sections/BlogBlockSection'
import ContentContainer from '../components/ContentContainer'
import BlogContent from '../components/BlogContent'
import BlogHero from '../components/BlogHero'
import BlogNav from '../components/BlogNav'
import ShowMob from '../components/ShowMob'
import FormSubscribe from '../components/FormSubscribe'
import GeneralContacts from '../components/GeneralContacts'
import Share from '../components/Share'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Layout from "../components/Layout";

const ServicesSubpageTemplate = ({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  location,
  ...rest
}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiServicesSubpages.en_disabled) {
      changeLanguage('de')
    }
  }, [])


  return (
    <Layout dataLocales={data} location={location}>
      {data.allStrapiService.edges.map(i => {
        if (i.node.page_url === crumbs[2].crumbLabel) {
          crumbs[2].crumbLabel = i.node.name.de
          crumbs[3].crumbLabel = data.strapiServicesSubpages.title.de
        }
        if (i.node.page_url === crumbs[3].crumbLabel) {
          crumbs[3].crumbLabel = i.node.name.en
          crumbs[4].crumbLabel = data.strapiServicesSubpages.title.en
        }
      })}
      <Header dataLang={data.strapiServicesSubpages.en_disabled} />
      <SEO
        title={
          data.strapiServicesSubpages.seo_page_title &&
          data.strapiServicesSubpages.seo_page_title[language]
        }
        description={
          data.strapiServicesSubpages.seo_page_description &&
          data.strapiServicesSubpages.seo_page_description[language]
        }
      />
      <BlogHero
        title={data.strapiServicesSubpages.title[language]}
        date={data.strapiServicesSubpages.date[language]}
        readingTime={data.strapiServicesSubpages.reading_time[language]}
        crumbs={crumbs}
      />
      <ContentContainer>
        <BlogNav navData={data.strapiServicesSubpages.navigation[language]} />
        <BlogContent content={data.strapiServicesSubpages.article[language]} />
      </ContentContainer>
      <BlogBlockSection
        data={data.allStrapiBlog.edges}
        title={t('sections.blog.title')}
        subtitle={t('sections.blog.subtitle')}
        location={location}
      />
      <ShowMob>
        <FormSubscribe />
        <Share />
      </ShowMob>
      <ShowMob>
        <GeneralContacts mb0 />
      </ShowMob>
    </Layout>
  )
}

export default ServicesSubpageTemplate

export const query = graphql`
  query ServicesSubpageTemplate($page_url: String!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    strapiServicesSubpages(page_url: {eq: $page_url}) {
      date {
        en
        de
      }
      article {
        de
        en
      }
      description_short {
        de
        en
      }
      en_disabled
      navigation {
        en
        de
      }
      page_url
      reading_time {
        en
        de
      }
      seo_page_description {
        de
        en
      }
      seo_page_title {
        de
        en
      }
      title {
        de
        en
      }
    }
    allStrapiService {
      edges {
        node {
          name {
            de
            en
          }
          service_types {
            de
            en
          }
          page_url
        }
      }
    }
    allStrapiBlog(sort: {order: DESC, fields: date_for_sort}) {
      edges {
        node {
          article {
            de
            en
          }
          date {
            de
            en
          }
          description_short {
            de
            en
          }
          navigation {
            de
            en
          }
          reading_time {
            de
            en
          }
          page_url
          date_for_sort
          published_at
          title {
            de
            en
          }
        }
      }
    }
  }
`
