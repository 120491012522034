import React from 'react'
// import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import {Wrapper, InnerWrapper} from './BlogNav.styles'
import ContentContainer from '../ContentContainer'

const BlogNav = ({children, navData, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <InnerWrapper>
        <MarkdownView markdown={navData} options={{strikethrough: true, noHeaderId: true}} />
      </InnerWrapper>
    </ContentContainer>
  </Wrapper>
)

BlogNav.propTypes = {}
BlogNav.defaultProps = {}

export default BlogNav
