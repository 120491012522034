import React, {useEffect, useRef} from 'react'
// import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import {Wrapper, Inner} from './BlogContent.styles'

const BlogContent = ({children, content, ...rest}) => {
  const innerRef = useRef(null)

  useEffect(() => {
    const blogContentInner = document.querySelector('#blogContentInner')
    const innerChildrenTitlesArr = Array.from(
      blogContentInner.querySelectorAll('h1,h2,h3,h4,h5,h6')
    )

    // const innerChildrenTitlesArr = innerChildrenArr.filter(
    //   item =>
    //     item.tagName === 'H1' ||
    //     item.tagName === 'H2' ||
    //     item.tagName === 'H3' ||
    //     item.tagName === 'H4' ||
    //     item.tagName === 'H5' ||
    //     item.tagName === 'H6'
    // )

    innerChildrenTitlesArr.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.id = `title-${index + 1}`
    })
  }, [])

  return (
    <Wrapper {...rest}>
      <Inner ref={innerRef} id="blogContentInner">
        <MarkdownView markdown={content} options={{strikethrough: true, noHeadersId: true}} />
      </Inner>
    </Wrapper>
  )
}

BlogContent.propTypes = {}
BlogContent.defaultProps = {}

export default BlogContent
