import React from 'react'
// import PropTypes from 'prop-types'
import Calendar from 'images/icons/calendar.inline.svg'
import Time from 'images/icons/time.inline.svg'
import {Breadcrumb} from 'gatsby-plugin-breadcrumb'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  Bottom,
  BottomItem,
  BottomItemText,
  BreadcrumbsWrapper,
  ShowMobile,
  Title,
  Wrapper,
} from './BlogHero.styles'
import ContentContainer from '../ContentContainer'
import Awards from '../Awards'

const BlogHero = ({title, date, readingTime, crumbs, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <BreadcrumbsWrapper>
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" ▸ "
            crumbLabel={title}
            hiddenCrumbs={language === 'de' ? ['/en'] : ['/']}
          />
        </BreadcrumbsWrapper>
        <Title content={title} color="common.white" component="h1" fontWeight={700} />
        <Bottom>
          <BottomItem>
            <Calendar />
            <BottomItemText>{date}</BottomItemText>
          </BottomItem>
          <BottomItem>
            <Time />
            <BottomItemText>{readingTime}</BottomItemText>
          </BottomItem>
        </Bottom>
        <ShowMobile>
          <Awards transparentBg />
        </ShowMobile>
      </ContentContainer>
    </Wrapper>
  )
}

BlogHero.propTypes = {}
BlogHero.defaultProps = {}

export default BlogHero
